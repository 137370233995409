var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{class:[
      'container sign-up mx-auto px-0 row d-flex flex-lg-row justify-content-center flex-nowrap flex-column-reverse align-items-center mt-3',
      _vm.className,
    ]},[_c('div',{staticClass:"px-0 sign-up-form col-lg-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-4 col-sm-6 col-12 wow fadeInUp animated"},[_c('label',{staticClass:"form-label tw-text-gray-800"},[_vm._v(" "+_vm._s(_vm.$t("signup.firstName"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.first_name.$model),expression:"$v.user.first_name.$model",modifiers:{"trim":true}}],staticClass:"px-2 w-100",attrs:{"placeholder":_vm.$t('signup.firstName')},domProps:{"value":(_vm.$v.user.first_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.user.first_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('VulidateError',{attrs:{"validationField":_vm.$v.user.first_name,"params":[
                'required',
                'alphaOnlyWithSpaces',
                { maxLength: { number: '50' } },
              ]}})],1),_c('div',{staticClass:"mb-4 col-sm-6 col-12 wow fadeInUp"},[_c('label',{staticClass:"form-label tw-text-gray-800"},[_vm._v(" "+_vm._s(_vm.$t("signup.lastName"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.last_name.$model),expression:"$v.user.last_name.$model",modifiers:{"trim":true}}],staticClass:"px-2 w-100",attrs:{"placeholder":_vm.$t('signup.lastName')},domProps:{"value":(_vm.$v.user.last_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.user.last_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('VulidateError',{attrs:{"validationField":_vm.$v.user.last_name,"params":[
                'required',
                'alphaOnlyWithSpaces',
                { maxLength: { number: '50' } },
              ]}})],1)]),_c('div',{staticClass:"mb-4 col-12 wow fadeInUp"},[_c('label',{staticClass:"form-label tw-text-gray-800"},[_vm._v(" "+_vm._s(_vm.$t("signup.email"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.email.$model),expression:"$v.user.email.$model",modifiers:{"trim":true}}],staticClass:"px-2 w-100",attrs:{"placeholder":_vm.$t('signup.email')},domProps:{"value":(_vm.$v.user.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.user.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('VulidateError',{attrs:{"validationField":_vm.$v.user.email,"params":[]}})],1),_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"w-100 sign-up-btn",attrs:{"type":"submit","disabled":!_vm.validForm}},[_vm._v(" "+_vm._s(_vm.$t("signup.signUp"))+" ")])])]),(!_vm.isAlMostDone)?_c('p',{staticClass:"text-center tw-mt-5"},[_c('span',{staticClass:"already-a-user tw-text-[#5c5c5c] regular-poppins-font"},[_vm._v(" "+_vm._s(_vm.$t("signUp.alreadyUser"))+"  ")]),_c('router-link',{staticClass:"sign-in text-decoration-none regular-poppins-font log-btn tw-text-primary tw-font-semibold",attrs:{"to":{ name: 'Login' }}},[_vm._v(" "+_vm._s(_vm.$t("signUp.signIn")))])],1):_vm._e(),_c('div',{staticClass:"my-4 d-flex justify-content-between align-items-baseline"},[_c('div',{class:[
            _vm.isModalVisible ? 'horizontal-line-black' : 'horizontal-line-black',
          ]}),_c('div',{staticClass:"tw-text-[#808285]",class:[
            _vm.isModalVisible ? 'small text-white' : 'light-gray-text small',
          ]},[_vm._v(" "+_vm._s(_vm.$t("signup.orSignUpWith"))+" ")]),_c('div',{class:[
            _vm.isModalVisible ? 'horizontal-line-black' : 'horizontal-line-black',
          ]})]),_c('div',{staticClass:"mt-2 !tw-flex row justify-content-center"},[_c('GoogleAuthBtn',{attrs:{"type":"2","meeting_reserved":true}})],1),(!_vm.isAlMostDone)?_c('p',{staticClass:"text-center tw-mt-6 tw-mx-4"},[_c('span',{staticClass:"already-a-user md:tw-text-lg tw-text-base tw-text-[#494d55] regular-poppins-font"},[_vm._v(" "+_vm._s(_vm.$t("signUp.areYouTalent"))+"  ")]),_c('router-link',{staticClass:"sign-in text-decoration-none regular-poppins-font md:tw-text-lg tw-text-base log-btn tw-text-primary tw-font-semibold",attrs:{"to":{ name: 'talent-signup' }}},[_vm._v(" "+_vm._s(_vm.$t("signUp.signupAsTalent")))])],1):_vm._e()])]),_c('OTPModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"email":_vm.user.email,"remainingOTP":_vm.remainingOTP},on:{"close":function($event){_vm.isModalVisible = false},"handleOtpResend":function($event){return _vm.handleOtpResend($event)},"closeSignup":_vm.closeSignUp}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }