<template>
  <div>
    <section
      :class="[
        'container sign-up mx-auto px-0 row d-flex flex-lg-row justify-content-center flex-nowrap flex-column-reverse align-items-center mt-3',
        className,
      ]"
    >
      <div class="px-0 sign-up-form col-lg-12">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="mb-4 col-sm-6 col-12 wow fadeInUp animated">
              <label class="form-label tw-text-gray-800">
                {{ $t("signup.firstName") }}
              </label>
              <input
                class="px-2 w-100"
                v-model.trim="$v.user.first_name.$model"
                :placeholder="$t('signup.firstName')"
              />
              <!-- ********* Validation Error*********** -->
              <VulidateError
                :validationField="$v.user.first_name"
                :params="[
                  'required',
                  'alphaOnlyWithSpaces',
                  { maxLength: { number: '50' } },
                ]"
              />
              <!-- ************************************* -->
            </div>

            <div class="mb-4 col-sm-6 col-12 wow fadeInUp">
              <label class="form-label tw-text-gray-800">
                {{ $t("signup.lastName") }}
              </label>
              <input
                class="px-2 w-100"
                v-model.trim="$v.user.last_name.$model"
                :placeholder="$t('signup.lastName')"
              />
              <!-- ********* Validation Error*********** -->
              <VulidateError
                :validationField="$v.user.last_name"
                :params="[
                  'required',
                  'alphaOnlyWithSpaces',
                  { maxLength: { number: '50' } },
                ]"
              />
              <!-- ************************************* -->
            </div>
          </div>

          <div class="mb-4 col-12 wow fadeInUp">
            <label class="form-label tw-text-gray-800">
              {{ $t("signup.email") }}
            </label>
            <input
              class="px-2 w-100"
              v-model.trim="$v.user.email.$model"
              :placeholder="$t('signup.email')"
            />
            <!-- ********* Validation Error*********** -->
            <VulidateError :validationField="$v.user.email" :params="[]" />
            <!-- ************************************* -->
          </div>

          <div class="mt-2">
            <button
              type="submit"
              class="w-100 sign-up-btn"
              :disabled="!validForm"
            >
              {{ $t("signup.signUp") }}
            </button>
          </div>
        </form>

         <p class="text-center tw-mt-5"  v-if="!isAlMostDone">
          <span class="already-a-user tw-text-[#5c5c5c] regular-poppins-font">
            {{ $t("signUp.alreadyUser") }} &nbsp;</span
          >
          <router-link
            :to="{ name: 'Login' }"
            class="sign-in text-decoration-none regular-poppins-font log-btn tw-text-primary tw-font-semibold"
          >
            {{ $t("signUp.signIn") }}</router-link
          >
        </p>
        
        <div class="my-4 d-flex justify-content-between align-items-baseline">
          <div
            :class="[
              isModalVisible ? 'horizontal-line-black' : 'horizontal-line-black',
            ]"
          ></div>
          <div
            :class="[
              isModalVisible ? 'small text-white' : 'light-gray-text small',
            ]"
            class="tw-text-[#808285]"
          >
            {{ $t("signup.orSignUpWith") }}
          </div>
          <div
            :class="[
              isModalVisible ? 'horizontal-line-black' : 'horizontal-line-black',
            ]"
          ></div>
        </div>

        <div class="mt-2 !tw-flex row justify-content-center">
          <GoogleAuthBtn type="2" :meeting_reserved="true" />
        </div>
        
          <p class="text-center tw-mt-6 tw-mx-4" v-if="!isAlMostDone">
          <span class="already-a-user md:tw-text-lg tw-text-base tw-text-[#494d55] regular-poppins-font">
            {{ $t("signUp.areYouTalent") }} &nbsp;</span
          >
          <router-link
            :to="{ name: 'talent-signup' }"
            class="sign-in text-decoration-none regular-poppins-font md:tw-text-lg tw-text-base log-btn tw-text-primary tw-font-semibold"
          >
            {{ $t("signUp.signupAsTalent") }}</router-link
          >
     </p>
      </div>
    </section>

    
    <OTPModal
      v-show="isModalVisible"
      @close="isModalVisible = false"
      @handleOtpResend="handleOtpResend($event)"
      :email="user.email"
      :remainingOTP="remainingOTP"
      @closeSignup="closeSignUp"
    />
  </div>
</template>
<script>
import { required, maxLength, email } from "vuelidate/lib/validators";
import { alphaOnlyWithSpaces } from "../../utils/utils";
import VulidateError from "../../components/shared/VulidateError/vulidateError.vue";
import GoogleAuthBtn from "../../components/shared/googleAuthBtn/googleAuthBtn.vue";
import OTPModal from "../../components/OTPModal/index.vue";
import store from "@/store";

export default {
  name: "CLientSignup",
  props: ["className", "closeModal", "isAlMostDone"],
  data: function () {
    return {
      isModalVisible: false,
      remainingOTP: null,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        type: 2,
      },
    };
  },
  validations: {
    user: {
      first_name: {
        required,
        maxLength: maxLength(50),
        alphaOnlyWithSpaces,
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        alphaOnlyWithSpaces,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    validForm: function () {
      return !this.$v.$invalid;
    },
    window: () => window,
    console: () => console,
  },
  methods: {
    handleOtpResend($event) {
      this.remainingOTP = $event;
    },
    async submit() {
      let loader = this.$loading.show({});
      this.$store
        .dispatch("auth/register", {
          ...this.user,
          marketing_campaign: this.$route.query.Campaign,
          signupSource: store.getters["shared/getUTMParameters"],
        })
        .then((res) => {
          this.remainingOTP = res.otp
          this.user.user_id = res.id;
          if (window.gtag) window.gtag("event", "client sign-up submitted", this.user)

          localStorage.setItem("meeting_reserved", true);
          this.isModalVisible = true;
        }).catch((err) => {
          if (
            err.response &&
            err.response.status == 422 &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].type == "otp"
          ) {
            localStorage.setItem("meeting_reserved", true);
            this.remainingOTP = 0
            this.isModalVisible = true;
          } else {
            throw err;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    closeSignUp() {
      this.$emit("closeSignup");
    },
  },
  components: {
    VulidateError,
    OTPModal,
    GoogleAuthBtn,
  },
};
</script>

<style scoped src="./signup.css"></style>